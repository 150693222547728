import { ButtonText } from '@/shared/ui/button';
import { Typography } from '@/shared/ui/typography';
import { useError } from '@/store/api/error';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { NextSeo } from 'next-seo';
import Image from 'next/image';

const navLinks = {
  home: {
    styles: 'mb:max-tb:ml-[86px]',
  },
  services: {
    styles: 'mb:max-tb:ml-[107px]',
  },
  career: {
    styles: 'mb:max-tb:ml-[103px]',
  },
};

export const ErrorPage = () => {
  const { title, description, links } = useError();
  return (
    <>
      <NextSeo title="404" description="Страница не найдена" />
      <motion.section
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.7, delay: 1 }}
        viewport={{ once: true }}
        className="dk:overflow-hidden dk:pt-[135px] dk:pl-[77px] tb:pt-[166px] tb:pl-[54px] dk:gap-x-[108px] tb:gap-x-[70px] mb:max-tb:flex-col-reverse mb:max-tb:items-center mb:max-tb:mt-[78px] mb:max-tb:pb-[121px] container relative flex flex-wrap"
      >
        <div className="mb:max-tb:items-center dk:max-w-[479px] tb:max-w-[386px] mb:max-w-[306px] flex flex-col">
          <Typography
            variant="titleX"
            className="tb:max-dk:text-titleL mb:max-tb:text-titleM mb:max-tb:text-center mb:max-tb:mt-[42px] text-black"
          >
            {title}
          </Typography>
          <Typography
            variant="textM"
            className="text-gray-2 dk:mt-[18px] tb:mt-[12px] mb:mt-[5px] mb:max-tb:text-textS mb:max-tb:text-center"
          >
            {description}
          </Typography>
          <div className="mb:max-tb:flex-col dk:gap-[56px] dk:mt-[46px] tb:mt-[46px] tb:gap-[37px] mb:max-tb:w-[100%] mb:max-tb:mt-[39px] mb:max-tb:gap-[24px] flex">
            {[
              { ...links?.home, ...navLinks.home },
              { ...links?.services, ...navLinks.services },
              { ...links?.career, ...navLinks.career },
            ].map((item) => (
              <ButtonText
                href={item?.href ?? ''}
                className={cn(
                  'text-textButtonCaps uppercase text-black',
                  item.styles,
                )}
                key={item.title}
                withArrow
              >
                {item.title}
              </ButtonText>
            ))}
          </div>
        </div>
        <div className="dk:w-[469px] dk:h-[238px] tb:w-[349px] tb:h-[177px] dk:mt-[-14px] tb:mt-[3px] mb:w-[235px] mb:h-[119px] relative">
          <Image src="/images/error-page/404.svg" alt="error" fill />
        </div>
        <div className="dk:mb-[0px] dk:w-[902px] dk:h-[214px] tb:w-[902px] tb:h-[300px] dk:mt-[9px] dk:left-[-73px] tb:left-[-132px] tb:mt-[2px] tb:mb-[12px] relative">
          <Image
            className="dk:block hidden"
            src="/images/error-page/Path.svg"
            alt="error"
            fill
          />
          <Image
            className="tb:max-dk:block hidden"
            src="/images/error-page/Path_Tablet.svg"
            alt="error"
            fill
          />
        </div>
      </motion.section>
    </>
  );
};
