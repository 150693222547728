import { ErrorPage } from '@/modules/error-page';
import { wrapper } from '@/store';
import { api } from '@/store/api';
import { errorApi } from '@/store/api/error';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

export const getStaticProps = wrapper.getStaticProps(
  (store) =>
    async ({ locale }) => {
      store.dispatch(api.endpoints.layout.initiate({ locale }));
      store.dispatch(errorApi.endpoints.error.initiate({ locale }));

      await Promise.all(store.dispatch(api.util.getRunningQueriesThunk()));

      return {
        props: {
          ...(await serverSideTranslations(locale as string, ['common'])),
        },
        revalidate: Number.parseInt(process.env.NEXT_REVALIDATE ?? '300'),
      };
    },
);
export default ErrorPage;
